import {
  Alert, AvatarGroup, Box, Divider, Link, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import CabinetPage from "../../components/Common/CabinetPage";
import colors from "../../colors";
import { ReactNode, useMemo, useState } from "react";
import CabAvatar from "@CabComponents/CabAvatar";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoAdd, IoArrowBack, IoCalendarOutline, IoClose, IoCloseCircleOutline, IoPeopleOutline } from "react-icons/io5";
import { ContactDenorm, ContactOrgDenorm, Leader } from "../../store";
import { CabTabs } from "@CabComponents/CabTabs";
import { CabCheckbox } from "@CabComponents/CabCheckbox";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { useFieldArray, useForm } from "react-hook-form";
import {
  AppCrmContactNotesPartialUpdateApiArg,
  AppCrmContactTasksPartialUpdateApiArg,
  Contact, ContactCategory, ContactField, ContactFieldTemplate, ContactNote, ContactTask, ContactType
} from "../../store/cabinetApi/generated/crm";
import CabSpinner from "@CabComponents/CabSpinner";
import { FormController } from "../../utils/formUtils";
import { CabModal } from "@CabComponents/CabModal";
import { Trigger } from "../../store/utils";
import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { AnalyticsEvent } from "../../store/cabinetApi/generated/analytics";
import { DateTime } from "luxon";
import { BasicInfoListItem, Widget, WidgetList, WidgetListItem } from "../../components/CRM/SharedCrmComponents";
import { getLeaderIconSrc } from "../../utils/leaderUtils";
import { CabExecPicker } from "@CabComponents/CabExecPicker";


const tabs = [
  { label: 'Notes' },
  { label: 'Meetings' },
];

const CRMContactView = ({
  contact, orgOptions, categoryOptions, typeOptions, fieldTemplates,
  onNavigateToContacts, onNavigateToDataView, onSaveBasicInfo, onSaveField, loading, loadingEvents,
  tasks, notes, events, onAddNote, onUpdateNote, onDeleteNote, onAddTask, onUpdateTask, onDeleteTask, onSetOrgString,
  relationshipLeaders, leaderOptions, onUpdateRelationships, onNavigateToContactOrg
}: {
  contact: ContactDenorm | null;
  onNavigateToContacts: () => void;
  onNavigateToDataView: (category: string) => void;
  onSaveBasicInfo: <T extends keyof Contact>(field: T, value: Contact[T]) => void;
  onSaveField: <T extends keyof Required<Contact>['fields']>(templateId: T, value: ContactField) => void;
  orgOptions: ContactOrgDenorm[];
  categoryOptions: ContactCategory[];
  typeOptions: ContactType[];
  fieldTemplates: ContactFieldTemplate[];
  loading: boolean;
  tasks: ContactTask[];
  notes: ContactNote[];
  events: AnalyticsEvent[];
  leaderOptions: Leader[];
  relationshipLeaders: Leader[];
  loadingEvents: boolean;
  onAddNote: Trigger<ContactNote, ContactNote>;
  onUpdateNote: Trigger<AppCrmContactNotesPartialUpdateApiArg, ContactNote>;
  onDeleteNote: Trigger<ContactNote['id'], unknown>;
  onAddTask: (t: { title: string }) => Promise<void>;
  onUpdateTask: Trigger<AppCrmContactTasksPartialUpdateApiArg, ContactTask>;
  onDeleteTask: Trigger<ContactTask['id'], unknown>;
  onSetOrgString: (val: string) => void;
  onUpdateRelationships: (leaderRelationships: Leader['id'][]) => Promise<void>;
  onNavigateToContactOrg?: () => void;
}) => {
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [editNote, setEditNote] = useState<ContactNote['id'] | null>(null);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [editTask, setEditTask] = useState<ContactTask['id'] | null>(null);
  const [showRelationshipsModal, setShowRelationshipsModal] = useState(false);

  const handleAddEditNote = async ({ body }: { body: string }) => {
    if (!contact) return;

    if (editNote != null) {
      await onUpdateNote({ id: editNote, patchedContactNote: { body } });
      setEditNote(null);
    } else {
      await onAddNote({
        id: -1,
        body: body,
        updated_at: '',
        contacts: [contact.id],
        teammates: [],
        company: [],
        allowed_users: [],
      });
    }

    setShowNoteModal(false);
  };

  const handleAddEditNoteCancel = () => {
    setEditNote(null);
    setShowNoteModal(false);
  };

  const handleClickNote = (id: ContactNote['id']) => {
    setEditNote(id);
    setShowNoteModal(true);
  };

  const handleAddEditTask = async ({ title }: { title: string }) => {
    if (editTask != null) {
      await onUpdateTask({ id: editTask, patchedContactTask: { title } });
      setEditTask(null);
    } else {
      await onAddTask({
        title: title,
      });
    }

    setShowTaskModal(false);
  };

  const handleAddEditTaskCancel = () => {
    setEditTask(null);
    setShowTaskModal(false);
  };

  const handleClickTask = (id: ContactTask['id']) => {
    setEditTask(id);
    setShowTaskModal(true);
  };

  const handleUpdateRelationships = async ({ leaderRelationships }: { leaderRelationships: Pick<Leader, 'id'>[] }) => {
    await onUpdateRelationships(leaderRelationships.map(l => l.id));
    setShowRelationshipsModal(false);
  };

  return (
    <CabinetPage
      pageName="Contact"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between'>
          <CabButton
            size="small"
            icon={<CabIcon alt='Back' Icon={IoArrowBack} />}
            buttonType="tertiary"
            onClick={onNavigateToContacts}
          >
            All Contacts
          </CabButton>

          <CabCollapseMenu
            popOverSx={theme => ({ marginTop: theme.spacing(0.5), width: 500 })}
            target={
              <CabButton
                buttonType="primary"
                icon={<CabIcon Icon={IoAdd} alt=''/>}
              >
                Create
              </CabButton>
            }
            buttons={
              <Box width={260}>
                <CabButton 
                  buttonType="text"
                  onClick={() => setShowNoteModal(true)}
                  sx={{ width: '100%', '& .button-text': { width: '100%', color: colors.black900, textAlign: 'left' } }}
                >
                  <Typography variant='h2' >Note</Typography>
                  <Typography variant="body2" color={colors.black800}>
                    Jot down a note for this contact
                  </Typography> 
                </CabButton>
                <Divider sx={{backgroundColor: colors.black200}}/>
                <CabButton 
                  buttonType="text"
                  onClick={() => setShowTaskModal(true)}
                  sx={{ width: '100%', '& .button-text': { width: '100%', color: colors.black900, textAlign: 'left' } }}
                >
                  <Typography variant='h2'>Reminder</Typography>
                  <Typography variant="body2" color={colors.black800}>
                    Set up a task with a due date that can be checked off when completed.
                  </Typography> 
                </CabButton>
              </Box>
            }
            popOverTitle=""
          />

        </Box>
      }
    >
      {contact ? (
        <Box display="flex" height="100%" width="100%" gap={3} padding={3} flexDirection="column">
          <Box display="flex" flex={1} gap={3} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box display="flex" flex={2}>
              <Widget>
                <Box display="flex" gap={1} padding={2} paddingLeft={1}>
                  <CabAvatar name={contact?.name || ''} color={colors.black500} />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h1">{contact?.name || contact.primary_email}</Typography>
                    <Typography>{contact.company?.name || ''}</Typography>
                  </Box>
                </Box>
              </Widget>
            </Box>
            <Box display="flex" flex={3} flexDirection="row" justifyContent="flex-end" gap={1} alignItems="flex-start">
              <CabButton
                onClick={() => setShowRelationshipsModal(true)}
                buttonType="text"
                sx={{ height: 26, textDecoration: 'underline' }}
              >
                View Relationships
              </CabButton>
              <AvatarGroup max={4} spacing={15}>
                {relationshipLeaders.map(leader => (
                  <CabAvatar
                    key={leader.id}
                    src={getLeaderIconSrc(leader)}
                    color={leader.color}
                    name={`${leader.first_name}
                    ${leader.last_name}`}
                    size="intermediate"
                  />
                ))}
              </AvatarGroup>
            </Box>
          </Box>
          <Box display="flex" flex={12} gap={3} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box display="flex" flex={2}>
              <Widget>
                <BasicInfoView
                  contact={contact}
                  onEditBasicInfo={onSaveBasicInfo}
                  onEditField={onSaveField}
                  orgOptions={orgOptions}
                  categoryOptions={categoryOptions}
                  typeOptions={typeOptions}
                  fieldTemplates={fieldTemplates}
                  onSetOrgString={onSetOrgString}
                  onNavigateToOrg={onNavigateToContactOrg}
                />
              </Widget>
            </Box>
            <Box display="flex" flex={3} flexDirection="column" gap={3}>
              <Box display="flex" flex={3}>
                <Widget header="Reminders">
                  <RemindersView
                    reminders={tasks}
                    onDeleteTask={onDeleteTask}
                    onUpdateTask={onUpdateTask}
                    onClickTask={handleClickTask}
                  />
                </Widget>
              </Box>
              <Box display="flex" flex={3}>
                <Widget>
                  <CabTabs
                    tabs={tabs} align="left" hideDivider
                    sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}
                    tabPanelSx={{ paddingTop: 2, flex: 1 }}
                  >
                    <NotesView
                      notes={notes}
                      onDeleteNote={onDeleteNote}
                      onClickNote={handleClickNote}
                    />
                    {loadingEvents ? (
                      <Box height='100%' display='flex' alignItems='center'>
                        <CabSpinner scale={4} color='inherit' />
                      </Box>
                    ) : (
                      <MeetingsView events={events}/>
                    )}
                  </CabTabs>
                </Widget>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box hidden={loading} display='flex' justifyContent='center' marginTop={5}>
          <Alert severity="warning">
            Contact not found
          </Alert>
        </Box>
      )}
      <Box hidden={!loading} height='100%' display='flex' alignItems='center'>
        <CabSpinner scale={4} color='inherit' />
      </Box>

      {showNoteModal && (
        <AddNoteModal
          open={showNoteModal}
          onClose={handleAddEditNoteCancel}
          onSubmit={handleAddEditNote}
          note={editNote != null ? notes.find(n => n.id === editNote) : undefined}
        />
      )}

      {showTaskModal && (
        <AddTaskModal
          open={showTaskModal}
          onClose={handleAddEditTaskCancel}
          onSubmit={handleAddEditTask}
          task={editTask != null ? tasks.find(t => t.id === editTask) : undefined}
        />
      )}

      {showRelationshipsModal && (
        <EditRelationshipsModal
          open={showRelationshipsModal}
          onClose={() => setShowRelationshipsModal(false)}
          onSubmit={handleUpdateRelationships}
          leaderOptions={leaderOptions}
          leaderRelationships={relationshipLeaders}
        />
      )}

    </CabinetPage>
  );
};

const BasicInfoView = ({
  contact, orgOptions, categoryOptions, typeOptions, fieldTemplates, onEditBasicInfo, onEditField, onSetOrgString,
  onNavigateToOrg
}: {
  contact: ContactDenorm,
  onEditBasicInfo: (field: keyof Contact, value: string | number | null) => void;
  onEditField: (templateId: number, value: Required<Contact>['fields'][number]) => void;
  orgOptions: ContactOrgDenorm[]; categoryOptions: ContactCategory[]; typeOptions: ContactType[];
  fieldTemplates: ContactFieldTemplate[];
  onSetOrgString: (val: string) => void;
  onNavigateToOrg?: () => void;
}) => (
  <WidgetList>
    <BasicInfoListItem inputType="text" title="Name" inputValue={contact.name || ''}
      text={contact?.name} onSave={(val) => !Array.isArray(val) && onEditBasicInfo('name', val)}
    />
    <BasicInfoListItem inputType="text" title="Email" inputValue={contact.primary_email || ''}
      text={contact?.primary_email} onSave={(val) => !Array.isArray(val) && onEditBasicInfo('primary_email', val)}
    />
    <BasicInfoListItem inputType="autocomplete"
      selectOptions={orgOptions.map(o => ({ value: o.id, label: o.name }))}
      inputValue={contact.company?.id || null}
      title="Company" text={contact.company?.name}
      onSave={(val) => !Array.isArray(val) && onEditBasicInfo('company', val)}
      onInputChange={onSetOrgString} placeholder="Type to search all companies"
      noOptionsText="No companies match this search" onClick={onNavigateToOrg}
    />
    <BasicInfoListItem inputType="text" title="Title" inputValue={contact.title || ''}
      text={contact?.title} onSave={(val) => !Array.isArray(val) && onEditBasicInfo('title', val)}
    />
    <BasicInfoListItem inputType="select"
      selectOptions={[
        { value: '', label: 'Unassigned', color: colors.black300 },
        ...typeOptions.map(o => ({ value: o.id, label: o.name, color: o.color }))
      ]}
      inputValue={contact.contact_type?.id || null} color={contact.contact_type?.color || colors.black300}
      title="Internal/External" text={contact.contact_type?.name || 'Unassigned'}
      onSave={(val) => !Array.isArray(val) && onEditBasicInfo('contact_type', val)}
    />
    <BasicInfoListItem inputType="select"
      selectOptions={[
        { value: '', label: 'Unassigned', color: colors.black300 },
        ...categoryOptions.map(o => ({ value: o.id, label: o.name, color: o.color }))
      ]}
      inputValue={contact.contact_category?.id || null} color={contact.contact_category?.color || colors.black300}
      title="Category" text={contact.contact_category?.name || 'Unassigned'}
      onSave={(val) => !Array.isArray(val) && onEditBasicInfo('contact_category', val)}
    />

    {fieldTemplates.map(template => {
      const field = contact.fields?.[template.id];

      return (
        <BasicInfoListItem
          inputType={['single_select', 'multi_select'].includes(template.value_type) ? "select"
            // TODO: make date picker
            : template.value_type === 'date_type' ? 'text'
              : 'text'
          }
          selectOptions={template.contact_field_choices.map((o, idx) => ({ value: idx, label: o.label }))}
          inputValue={template.value_type === 'single_select' ? (field?.choices?.[0] ?? '')
            : field?.choices || field?.text_value || null}
          title={template.label}
          text={field?.choices
            ? field.choices.map(c => template.contact_field_choices[c].label).join(', ')
            : field?.text_value
            || ''}
          onSave={(val) => onEditField(
            template.id,
            template.value_type === 'single_select' && typeof val === 'number' ? { choices: [val] }
              : Array.isArray(val) ? { choices: val } : { text_value: val?.toString() }
          )}
          key={template.id}
        />
      );
    })}
  </WidgetList>
);

const RemindersView = ({ reminders, onDeleteTask, onUpdateTask, onClickTask }: {
  reminders: ContactTask[], onDeleteTask: Trigger<ContactTask['id'], unknown>;
  onUpdateTask: Trigger<AppCrmContactTasksPartialUpdateApiArg, ContactTask>;
  onClickTask: (id: ContactTask['id']) => void;
}) => (
  <WidgetList>
    {reminders.map(r => (
      <ReminderListItem
        key={r.id}
        title={r.title}
        checked={r.status === 'Completed'}
        onClick={() => onClickTask(r.id)}
        onCheck={(check) => onUpdateTask({ id: r.id, patchedContactTask: { status: check ? 'Completed' : 'To-Do' } })}
        onDelete={() => onDeleteTask(r.id)}
      />
    ))}
  </WidgetList>
);

const MeetingsView = ({ events }: { events: AnalyticsEvent[] }) => (
  events && events.length ? (
    <WidgetList>
      {events.map(e => (
        <MeetingListItem 
          key={e.id} 
          title={e.title} 
          text={
            <Box display='flex' gap={1} marginTop={.5}>
              <Box display='flex' gap={.25}>
                <CabIcon Icon={IoCalendarOutline} sx={{fontSize: 14, color: colors.black800}} />
                <Typography variant='body2' lineHeight={'16px'} sx={{color: colors.black800}}>
                  {`${DateTime.fromISO(e.start_date).toLocaleString(DateTime.DATE_FULL)}`}
                </Typography>
              </Box>
              <Box display='flex' gap={.25}>
                <CabIcon Icon={IoPeopleOutline} sx={{fontSize: 14, color: colors.black800}} />
                <Typography variant='body2' lineHeight={'16px'} sx={{color: colors.black800}}>
                  {`${e.attendees.length} attendees`}
                </Typography>
              </Box>
            </Box>
          } 
        />
      ))}
    </WidgetList>
  ) : (
    <>No Events for contact</>
  )
);

const NotesView = ({ notes, onClickNote, onDeleteNote }: {
  notes: ContactNote[], onDeleteNote: Trigger<ContactNote['id'], unknown>;
  onClickNote: (id: ContactNote['id']) => void;
}) => (
  <WidgetList>
    {notes.map(n => (
      <NoteListItem
        key={n.id}
        text={n.body || ''}
        onClick={() => onClickNote(n.id)}
        onDelete={() => onDeleteNote(n.id)}
      />
    ))}
  </WidgetList>
);

const ReminderListItem = ({ title, text, checked, onEdit, onCheck, onDelete, onClick }: {
  title: string; text?: string | null; checked?: boolean; onEdit?: () => void;
  onCheck: (check: boolean) => void; onDelete: () => void; onClick: () => void;
}) => (
  <WidgetListItem secondaryAction={
    <CabIcon Icon={IoClose} onClick={onDelete} />
  }>
    <ListItemIcon sx={{ minWidth: 35 }}>
      <CabCheckbox checked={checked} onChange={(e, c) => onCheck(c)} />
    </ListItemIcon>
    <ListItemText
      primary={<Link href="#" color={colors.black900} underline="hover" onClick={onClick}>{title}</Link>}
      secondary={text}
      primaryTypographyProps={{ sx: { textDecoration: checked ? 'line-through' : undefined } }}
    />
  </WidgetListItem>
);

const NoteListItem = ({ text, onDelete, onClick }: {
  text: string; onDelete: () => void; onClick: () => void;
}) => (
  <WidgetListItem secondaryAction={
    <CabIcon Icon={IoClose} onClick={onDelete} />
  }>
    <ListItemText
      primary={<Link href="#" color={colors.black900} underline="hover" onClick={onClick}>{text}</Link>}
    />
  </WidgetListItem>
);

const MeetingListItem = ({ title, text, onEdit }: {
  title: string, text?: ReactNode | null, onEdit?: () => void
}) => (
  <WidgetListItem>
    <ListItemText
      primary={title}
      secondary={text}
    />
  </WidgetListItem>
);

const AddNoteModal = ({ open, onClose, onSubmit, note }: {
  open: boolean; onClose: () => void; onSubmit: ({ body} : { body: string }) => void;
  note?: ContactNote,
}) => {
  const { control, handleSubmit, formState } = useForm<{ body: string }>({ defaultValues: {
    body: note?.body || '',
  } });

  return (
    <CabModal open={open} title={`${note ? 'Edit' : 'New'} Note`}
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
          {note ? 'Save' : 'Add'}
        </CabButton>
      </>}
    >
      <Box height={200}>
        <FormController name="body" label="Note" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }}) => (
            <CabTextInput inputRef={ref} {...field} multiline minRows={7} />
          )}
        />

      </Box>
    </CabModal>
  );
};

const AddTaskModal = ({ open, onClose, onSubmit, task }: {
  open: boolean; onClose: () => void; onSubmit: ({ title } : { title: string }) => void;
  task?: ContactTask,
}) => {
  const { control, handleSubmit, formState } = useForm<{ title: string }>({ defaultValues: {
    title: task?.title || '',
  } });

  return (
    <CabModal open={open} title={`${task ? 'Edit' : 'New'} Reminder`}
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
          {task ? 'Save' : 'Add'}
        </CabButton>
      </>}
    >
      <Box height={200}>
        <FormController name="title" label="Task" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }}) => (
            <CabTextInput inputRef={ref} {...field} multiline minRows={7} />
          )}
        />

      </Box>
    </CabModal>
  );
};

const EditRelationshipsModal = ({ open, onClose, onSubmit, leaderOptions, leaderRelationships }: {
  open: boolean; onClose:() => void;
  onSubmit: ({ leaderRelationships }: { leaderRelationships: Pick<Leader, 'id'>[] }) => void;
  leaderOptions: Leader[], leaderRelationships: Leader[],
}) => {
  const { control, handleSubmit, formState, watch } = useForm<{ leaderRelationships: Pick<Leader, 'id'>[] }>({
    defaultValues: { leaderRelationships }
  });

  const { fields: relationshipFields, append, remove } = useFieldArray({
    control, name: 'leaderRelationships',
    // need to specify this or it will override the leader `id`
    keyName: 'fieldId'
  });

  const leaderRelValues = watch('leaderRelationships');
  const lastFieldId = leaderRelValues.at(-1)?.id;

  const cabExecOptions = useMemo(() => leaderOptions.map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [leaderOptions]);

  const availableOptions = cabExecOptions.filter(o => !leaderRelValues.find(f => f.id === o.value));

  return (
    <CabModal open={open} title="Relationships"
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={handleSubmit(onSubmit)} disabled={!formState.isValid || formState.isSubmitting}>
          {formState.isSubmitting ? <CabSpinner scale={1} /> : 'Save'}
        </CabButton>
      </>}
    >
      <Box display="flex" flexDirection="column">
        {relationshipFields.map((relField, index) => (
          <FormController key={relField.fieldId} name={`leaderRelationships.${index}.id`} label=""
            control={control} rules={{ required: true, validate: (v) => v !== -1 }}
            render={({ field: { ref, ...field }}) => {
              const selectedOption = cabExecOptions.find(o => o.value === field.value);
              const options = selectedOption
                ? [selectedOption, ...availableOptions.filter(ao => ao.value !== selectedOption.value)]
                : availableOptions;

              return (
                <Box display="flex" gap={1}>
                  <CabExecPicker
                    {...field}
                    value={field.value === -1 ? '' : field.value}
                    options={options}
                    placeholder="Select a Teammate"
                    fullWidth
                  />
                  <CabIcon Icon={IoCloseCircleOutline} onClick={() => remove(index)} sx={{ marginLeft: 2 }} />
                </Box>
              );
            }}
          />
        ))}
        {availableOptions.length > 0 && (
          <CabButton
            onClick={() => append({ id: -1 })}
            icon={<CabIcon Icon={IoAdd} />}
            buttonType="tertiary"
            sx={{ width: '100%', marginTop: 2 }}
            disabled={lastFieldId === -1}
          >
            Add New Relationship
          </CabButton>
        )}
      </Box>
    </CabModal>
  );
};


export default CRMContactView;
